import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { ThemeProvider } from '@bluecodecom/theme';

import './i18n';

const App = lazy(() => import('./app'));

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbq?: any;
  }
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <LoaderProvider>
    <ThemeProvider observe>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </LoaderProvider>,
);
